import * as React from 'react';
import { navigate } from 'gatsby';
import { Auth } from 'aws-amplify';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
	Box,
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	Container,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { setUser, isLoggedIn } from '../../utils/auth';

import BackgroundImage from '../../images/background.jpg';

type Inputs = {
	username: string;
	password: string;
};

async function signIn(username: string, password: string) {
	try {
		const name = 'Vacanza Admin';
		const phoneNumber = '+5519981211215';
		const user = await Auth.signIn(username, password);

		if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
			// const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
			const updatedUser = await Auth.completeNewPassword(
				user, // the Cognito User Object
				password, // the new password
				// OPTIONAL, the required attributes
				{
					name,
					phone_number: phoneNumber,
				}
			);
		} else {
			// other situations
		}

		const userInfo = {
			...user.attributes,
			username: user.username,
		};

		setUser(userInfo);
	} catch (error) {
		console.log('error signing in', error);
	}
}

const AdminPage = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const onRecaptchaChange = value => {
		console.log('Captcha value:', value);
	};

	const onSubmit: SubmitHandler<Inputs> = async data => {
		const { username, password } = data;

		await signIn(username, password);
	};

	if (isLoggedIn()) {
		navigate('/admin/bookings');
	}

	return (
		<div
			style={{
				backgroundImage: `url(${BackgroundImage})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				minHeight: 'calc(100vh - 120px)',
			}}
		>
			<Container maxWidth='sm' sx={{ padding: 5 }}>
				<Paper elevation={3} sx={{ padding: 3 }}>
					<Typography
						variant='h5'
						sx={{ marginBottom: 3, textAlign: 'center' }}
						gutterBottom
					>
						Login de administrador
					</Typography>

					<Box sx={{ marginBottom: 3 }}>
						<Grid container spacing={2}>
							<Grid item md={12}>
								<TextField
									label='E-mail*'
									variant='outlined'
									error={Boolean(errors.username)}
									{...register('username', { required: true })}
									fullWidth
								/>
							</Grid>
							<Grid item md={12}>
								<TextField
									label='Senha*'
									variant='outlined'
									type='password'
									error={Boolean(errors.password)}
									{...register('password', { required: true })}
									fullWidth
								/>
							</Grid>
						</Grid>
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<ReCAPTCHA
							sitekey='6LeDCRkhAAAAAGTfe2fbdFbDCBXC2uzIIlLJ7vWb'
							onChange={onRecaptchaChange}
						/>

						<Button
							variant='contained'
							onClick={handleSubmit(onSubmit)}
							sx={{ marginTop: 3 }}
						>
							Enviar
						</Button>
					</Box>
				</Paper>
			</Container>
		</div>
	);
};

export default AdminPage;
